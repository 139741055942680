import type {
  GeoFilter,
  IGetAdsRequestFilter,
  IGetAdsRequestRange,
  ISearchPageFilter,
  ISearchPageFilterValue,
} from 'api/types/searchPageApiTypes';
import { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import { provinces } from 'helpers/constants';
import { areasRepository } from 'repositories/AreasRepository';
import { DEFAULT_COORDINATES } from 'features/location/Location.constants';
import { isOk } from 'domains/Result';
import { separateValues } from 'utils/CombineAndSeparateValues';
import { CURRENCIES, ResponseMakeModelType, UrlParamType } from 'types';
import { DropDownValue } from 'features/filters/components/PriceSelectRange/PriceSelectRange';
import { TypePrice } from 'api/types/searchPageApiTypes';
import { Filter, getFilter, getRange, Range } from 'domains/Filter';
import { ParsedUrlQuery } from 'querystring';

const mapRangesRequest = (ranges: IGetAdsRequestRange[]) =>
  ranges.reduce<IGetAdsRequestRange[]>((items, range) => {
    // remove empty ranges
    if (typeof range.to === 'undefined' && typeof range.from === 'undefined') {
      return items;
    }
    if (range.name === 'price') {
      const item = { ...range };
      const currency = item.currency;
      const typePrice = item.typePrice;
      delete item.currency;
      delete item.typePrice;
      items.push({
        ...item,
        name:
          currency === 'GBP'
            ? 'sterlingPrice'
            : typePrice === 'pricePerMonth'
            ? 'pricePerMonth'
            : 'price',
      });
    } else {
      items.push(range);
    }
    return items;
  }, []);

const mapFiltersRequest = (filters: IGetAdsRequestFilter[]) =>
  filters.reduce<IGetAdsRequestFilter[]>((items, filter) => {
    // Remove empty filters
    if (!filter.values.length) {
      return items;
    }
    if (filter.values.length === 1 && typeof filter.values[0] === 'undefined') {
      return items;
    }
    items.push(filter);
    return items;
  }, []);

const mapOptionsRanges = (from: DropDownValue, to: DropDownValue) => {
  if (from.selectedValue) {
    return [
      to.options[0],
      ...(to.options &&
        to.options.filter((item) => {
          return (
            item.label !== 'Max' &&
            Number(from.selectedValue) <= Number(item.value)
          );
        })),
    ];
  } else return to.options;
};

const mapToMultiSelect = (values?: ISearchPageFilterValue[]) =>
  values
    ? values.map((item) => ({
        value: item.value ?? '',
        label: item.displayName ?? '',
        count: item.count ?? '',
      }))
    : [];

const mapSelectedOptionsToMultiSelect = (
  values: ISearchPageFilterValue[] | null,
) => (values ? values.map(({ value }) => value ?? '') : []);

const mapToSingleSelect = (values?: ISearchPageFilterValue[]) =>
  values
    ? values.map((item) => ({
        value: item.value ?? '',
        label: item.displayName ?? '',
      }))
    : [];

const mapToPriceSelect = (
  values?: ISearchPageFilterValue[],
  currency?: string,
) =>
  values
    ? values.map((item) => ({
        value: item.value ?? '',
        label:
          item.value !== ''
            ? `${currency}${item.displayName}`
            : item.displayName ?? '',
      }))
    : [];

const createHiddenFilters = (): ISearchPageFilter[] => {
  const HIDDEN_FILTERS = [
    'financeProvider',
    'cashOffer',
    'dealerId',
    'carFinance',
  ];
  return HIDDEN_FILTERS.map((key) => {
    return {
      filterType: { id: 0, name: 'hidden' },
      displayHint: '',
      id: 0,
      name: key,
      displayName: key,
      maxLength: 0,
      searchQueryGroup: 'filters',
      values: [],
      variant: 'HIDDEN',
    };
  });
};

interface MapGeoFilterProps {
  radius?: string | null;
  latitude?: string;
  longitude?: string;
  county: string | string[] | null;
  countyTown: string | null;
}

const mapGeoFilter = ({
  radius,
  latitude,
  longitude,
  county,
  countyTown,
}: MapGeoFilterProps) => {
  const formattedCounty = Array.isArray(county) ? county[0] : county;
  if (latitude && longitude && radius) {
    return {
      geoFilter: {
        lat: parseFloat(latitude),
        lon: parseFloat(longitude),
        rad: parseInt(radius),
        countyTown,
        county: formattedCounty,
      },
    };
  } else if (county) {
    return {
      geoFilter: {
        lat: 0,
        lon: 0,
        rad: 0,
        county: formattedCounty,
        countyTown: null,
      },
    };
  } else return null;
};

// For server-rendering
const mapCountyList = (
  countyParam: string[] | null,
  countyList: ListItem[],
) => {
  return countyParam &&
    countyParam.length === 1 &&
    !provinces.includes(countyParam[0])
    ? countyList.map((county) => {
        const formattedDisplayName =
          county.value.toLowerCase() === countyParam[0].toLowerCase()
            ? `<b>${county.displayName}</b>`
            : null;
        return {
          ...county,
          formattedDisplayName,
        };
      })
    : [
        {
          ...countyList[0],
          formattedDisplayName: `<b>${countyList[0].displayName}</b>`,
        },
        ...countyList.slice(1),
      ];
};

export const mapLocationDisplay = (countyParam: string[] | null) => {
  // Allow for multiple areas or search by province (legacy functionality)
  return countyParam &&
    (countyParam.length > 1 || provinces.includes(countyParam[0]))
    ? `${countyParam[0]}${
        countyParam.length > 1 ? ` (+${countyParam.length - 1})` : ''
      }`
    : null;
};

interface IMapSelectedCounyAndCoordinatesAndLocationDisplay {
  sectionQueryParams: {
    [filter: string]: UrlParamType;
  };
  counties: {
    displayName: string;
    value: string;
  }[];
  countyParam: string[] | null;
  countyTownParam: string | null;
}
const mapSelectedCounyAndCoordinatesAndLocationDisplay = async ({
  sectionQueryParams,
  counties,
  countyParam,
  countyTownParam,
}: IMapSelectedCounyAndCoordinatesAndLocationDisplay) => {
  const [selectedCounty] = sectionQueryParams.area
    ? counties.filter(
        (county: { displayName: string; value: string }) =>
          county.value === sectionQueryParams.area,
      )
    : countyParam
    ? counties.filter(
        (county: { displayName: string; value: string }) =>
          county.value.toLowerCase() === countyParam[0].toLowerCase(),
      )
    : [];

  let coordinates = DEFAULT_COORDINATES;
  let locationDisplay = selectedCounty ? selectedCounty.displayName : '';

  if (countyTownParam && selectedCounty) {
    const areas = await areasRepository.getCountyAreas(
      selectedCounty.value,
      selectedCounty.displayName,
    );
    if (isOk(areas)) {
      const [match] = areas.ok.filter(
        (area) =>
          area.displayName ===
          `${countyTownParam}, ${selectedCounty.displayName}`,
      );
      if (match && match.value) {
        const [latitude, longitude] = separateValues(match.value);
        coordinates = {
          latitude,
          longitude,
        };
        locationDisplay = `${countyTownParam}, ${selectedCounty.displayName}`;
      }
    }
  }

  return {
    selectedCounty,
    coordinates,
    locationDisplay,
  };
};

const getTextInputDisplayName = (filters: ISearchPageFilterValue[]) => {
  const filtersLength = filters.length;
  const firstFiltersValue = filters[0].value;
  const firstFiltersDisplayName = filters[0].displayName;
  if (firstFiltersValue === '') return filters[0].displayName;
  else if (filtersLength > 1)
    return `${firstFiltersDisplayName} (+${filtersLength - 1})`;
  else return firstFiltersDisplayName;
};

const mapGeoFilterRequest = (geoFilter?: GeoFilter) =>
  geoFilter?.countyTown !== null ? geoFilter : undefined;

const mapLegacyAreaFilterValues = (
  filterValues: IGetAdsRequestFilter[],
  counties?: string | null,
  countyTown?: string | null,
) => {
  const updatedFilterValues = [...filterValues];
  const includesAreaFilter =
    filterValues.filter((item) => item.name === 'area').length > 0;

  if (!includesAreaFilter && counties && !countyTown) {
    updatedFilterValues.push({
      name: 'area',
      values: [counties],
    });
  }

  return updatedFilterValues;
};

const getFilterByGroup = (filtersData: ISearchPageFilter[], group: string) =>
  filtersData.filter(
    (filterData) =>
      filterData.searchQueryGroup === group &&
      filterData.filterType.name !== 'Group',
  );

const mapRangeValue = (
  from?: string,
  to?: string,
  currency?: CURRENCIES,
  typePrice?: TypePrice,
) => ({
  ...(from && { from }),
  ...(to && { to }),
  ...(currency && { currency }),
  ...(typePrice && { typePrice }),
});

const mapFilters = (
  filtersData: ISearchPageFilter[],
  filters?: Filter[],
): Filter[] =>
  getFilterByGroup(filtersData, 'filters').map((filterData) => ({
    name: filterData.name,
    values: (filters && getFilter(filters, filterData.name)?.values) || [],
  }));

const mapRanges = (
  filtersData: ISearchPageFilter[],
  ranges?: Range[],
): Range[] =>
  getFilterByGroup(filtersData, 'ranges').map((filterData) => {
    const rangeSelected = ranges && getRange(ranges, filterData.name);
    return {
      name: filterData.name,
      ...mapRangeValue(
        rangeSelected?.from,
        rangeSelected?.to,
        rangeSelected?.currency,
        rangeSelected?.typePrice,
      ),
    };
  });

const groupByMake = (arrayObjects: any[], key: string) => {
  return arrayObjects.reduce((result, currentObject) => {
    const val = currentObject[key];
    result[val] = result[val] || [];
    currentObject.model && result[val].push(currentObject.model);
    return result;
  }, {});
};

export const groupModelsByMake = (makeModelParams: ResponseMakeModelType) =>
  groupByMake(mapMakeModel(makeModelParams), 'make');

const mapMakeModels = (
  makeModelParams: ResponseMakeModelType,
  query: ParsedUrlQuery,
) => {
  const modelsByMake: Record<string, string[]> =
    groupModelsByMake(makeModelParams);

  const id = (query.makeModelEditable || '') as string;
  const keys = Object.keys(modelsByMake);

  const result = [];

  for (let i = 0; i < 3; i++) {
    const key = `make_model_${i}`;
    const makeValue = keys[i] || '';
    const modelValue = modelsByMake[makeValue] || [];

    const isEditable =
      (i === 0 && keys.length === 0) ||
      (id === key && keys.length > 0) ||
      (i > 0 && id === '' && Boolean(keys[i - 1]) && makeValue === '');
    const isVisible = !isEditable && makeValue !== '' && id !== key;

    result.push({
      id: key,
      makeValue,
      modelValue,
      isEditable,
      isVisible,
    });
  }

  return result;
};

const mapMakeModel = (makeModelParams: ResponseMakeModelType) => {
  return makeModelParams
    ? makeModelParams.map((makeModelItem) => ({
        ...makeModelItem,
      }))
    : [];
};

export {
  mapFilters,
  mapRanges,
  mapMakeModels,
  mapMakeModel,
  mapRangeValue,
  mapRangesRequest,
  mapFiltersRequest,
  mapOptionsRanges,
  mapToMultiSelect,
  mapSelectedOptionsToMultiSelect,
  mapToSingleSelect,
  mapToPriceSelect,
  mapGeoFilter,
  mapCountyList,
  mapSelectedCounyAndCoordinatesAndLocationDisplay,
  mapGeoFilterRequest,
  mapLegacyAreaFilterValues,
  createHiddenFilters,
  getTextInputDisplayName,
};
